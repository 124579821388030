exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-templates-cell-line-template-js": () => import("./../../../src/templates/cell-line-template.js" /* webpackChunkName: "component---src-templates-cell-line-template-js" */),
  "component---src-templates-common-name-cells-template-js": () => import("./../../../src/templates/common-name-cells-template.js" /* webpackChunkName: "component---src-templates-common-name-cells-template-js" */)
}

